<template>
  <div>
    <app-header :buttons="buttons" @header-action="newShipmentRequest" />
    <Main>
      <a-row :gutter="15">
        <a-col :xxl="24" :xl="24" :lg="24" :md="24" :xs="24">
          <a-tabs v-model:activeKey="activeKey">
            <a-tab-pane key="shipmentRequests" :tab="$t('layout.shipmentRequest.requests')">
              <a-row :gutter="15">
                <a-col :xxl="24" :xl="24" :lg="24" :md="24" :xs="24">
                  <shipment-requests
                  :showTitle="false"
                />
                </a-col>
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="shipments"
            :tab="$t('views.shipmentRequest.shipments')">
              <a-col :xxl="24" :xl="24" :lg="24" :md="24" :xs="24">
                <shipments
                  :showTitle="false"
                />
              </a-col>
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </Main>
  </div>
</template>

<script>
import {
  defineComponent, computed, ref
} from 'vue';
import {useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {Main} from '@/components/shared/styledComponents/Main';
import {useAbility} from '@casl/vue';
import Shipments from '@/components/shipments/Shipments';
import ShipmentRequests from '@/components/shipmentRequests/ShipmentRequestsList';

export default defineComponent({
  name: 'ClientShipmentRequests',
  components: {
    Main,
    ShipmentRequests,
    Shipments
  },
  setup() {
    const routes = [{
      name: 'ClientShipmentRequests',
      breadcrumbName: 'layout.shipmentRequest.index'
    }];

    const store = useStore();
    const router = useRouter();

    const isClient = computed(() => store.state.session.userType === 'ClientUser');

    const buttons = computed(() => {
      return isClient.value && useAbility().can('manage', 'shipmentRequest') ? [{
        action: 'newShipmentRequest', icon: 'plus', label: 'layout.shipmentRequest.new'
      }] : undefined;
    });

    const newShipmentRequest = () => router.push({name: 'ClientNewShipmentRequest'});

    const activeKey = ref('shipmentRequests');


    return {
      routes,
      buttons,
      newShipmentRequest,
      activeKey
    };
  }
});
</script>
<style lang="scss" scoped>
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 10px;
}
.ant-dropdown {
  padding: 10px 0 !important;
}
.dropdown-filter {
  margin-bottom: 15px !important;
}

@media only screen and (min-width: 768px) {
  .details-padding {
  padding-top: 55px !important;
  }
}
</style>
