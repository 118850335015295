<template>
  <a-col :xs="24" class="table-search">
      <a-row :gutter="[10,0]" type="flex" justify="end" align="middle">
        <a-col class="dropdown-filter">
            <visible-columns-dropdown
            :dropdownColumnValue="dropdownColumnValue"
            @update:dropdownColumnValue="dropdownColumnValue = $event"
            :columnOptions="columnOptions"
            @resetColumnMenuClick="resetColumnMenuClick"
            @handleColumnMenuFilter="handleColumnMenuFilter"
            />
        </a-col>
        <a-col :xs="24" :sm="7" :md="7" :lg="5" :xl="5" :xxl="4">
            <a-input-search v-model:value="searchValue" :placeholder="$t('actions.search')" />
        </a-col>
      </a-row>
  </a-col>
  <a-col :xxl="24" :xl="24" :md="24" :xs="24">
    <div class="about-shipment-request-wrapper">
      <sd-cards :title="showTitle ? $t('layout.shipmentRequest.shipments') : ''" :headless="!showTitle">
        <shipments-requests-table
          :searchValue="searchValue"
          :visibleColumnValue="visibleColumnValue"
        />
      </sd-cards>
    </div>
  </a-col>
</template>
<script>
import {
  defineComponent, computed, ref
} from 'vue';
import {useStore} from 'vuex';
import ShipmentsRequestsTable from '@/components/shipmentRequests/Table';
import {useI18n} from 'vue-i18n';
import VisibleColumnsDropdown from '@/components/shared/table/VisibleColumnsDropdown';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'ClientShipmentRequests',
  components: {
    ShipmentsRequestsTable,
    VisibleColumnsDropdown
  },
  props: {
    showTitle: VueTypes.bool.def(true)
  },
  setup() {
    const {t} = useI18n();

    const store = useStore();
    const isClient = computed(() => store.state.session.userType === 'ClientUser');

    const searchValue = ref('');

    const columnVisible = ref(false);
    const columnVisibleSaved = computed(() => store.getters.shipmentRequestColumns);

    const currentColumns = () => !columnVisibleSaved.value ?['shipmentType', 'shippingFrom', 'shippingTo', 'reference',
      'shippingMethod'] : JSON.parse(columnVisibleSaved.value);

    const dropdownColumnValue = ref(currentColumns());
    const visibleColumnValue = ref(currentColumns());

    const columnOptions = ref([
      {
        label: t('models.shipmentRequest.attributes.shipmentType'),
        value: 'shipmentType'
      },
      {
        label: t('models.shipmentRequest.attributes.shippingFrom'),
        value: 'shippingFrom'
      },
      {
        label: t('models.shipmentRequest.attributes.shippingTo'),
        value: 'shippingTo'
      },
      {
        label: t('models.shipmentRequest.attributes.reference'),
        value: 'reference'
      },
      {
        label: t('models.shipmentRequest.attributes.shippingMethod'),
        value: 'shippingMethod'
      }
    ]);
    if (!isClient.value) {
      columnOptions.value.push({
        label: t('models.shipmentRequest.attributes.clientName'),
        value: 'clientName'
      });
      if (!columnVisibleSaved.value) {
        dropdownColumnValue.value.push('clientName');
        visibleColumnValue.value.push('clientName');
      }
    }

    const handleColumnMenuClick = () => {
      columnVisible.value = false;
    };
    const handleColumnMenuFilter = () => {
      handleColumnMenuClick();
      store.dispatch('setShipmentAndShipmentRequestColumns', {columnName: 'shipmentRequestColumns', values: dropdownColumnValue.value});

      visibleColumnValue.value = dropdownColumnValue.value;
    };
    const resetColumnMenuClick = () => {
      handleColumnMenuClick();
      store.dispatch('setShipmentAndShipmentRequestColumns', {columnName: 'shipmentRequestColumns', values: []});
      visibleColumnValue.value = dropdownColumnValue.value = [];
    };


    return {
      isClient,
      searchValue,
      columnVisible,
      columnOptions,
      visibleColumnValue,
      dropdownColumnValue,
      handleColumnMenuClick,
      handleColumnMenuFilter,
      resetColumnMenuClick
    };
  }
});
</script>
<style lang="scss" scoped>
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 10px;
}
.ant-dropdown {
  padding: 10px 0 !important;
}
.dropdown-filter {
  margin-bottom: 15px !important;
}

@media only screen and (min-width: 768px) {
  .details-padding {
  padding-top: 55px !important;
  }
}
</style>
