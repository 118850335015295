<template>
  <div>
    <table-style-wrapper>
      <table-wrapper class="table-responsive">
        <a-table
          :columns="visibleColumns"
          :data-source="dataSource ? dataSource.data : []"
          :rowKey="'identifier'"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
          :customRow="customRow"
          :class="isClient ? 'clickable-rows' : ''"
        >
          <template #createdAt="{record}">
            {{ record.createdAt ? moment(record.createdAt).format('DD MMM YYYY') : '--' }}
          </template>
          <template #published="{record}">
            <span :class="`status-text ${publishedClass(record)}`">
              {{ record.published ? $t('models.shipmentRequest.attributes.published') :
              $t('models.shipmentRequest.attributes.unpublished') }}
            </span>
          </template>
          <template #shipmentType="{record}">
            <span :class="`status-text ${requestTypeClass(record)}`">
              {{ $t(`models.shipmentRequest.attributes.shipmentTypes.${record.shipmentType}`) }}
            </span>
          </template>
          <template #shippingToNames="{record}">
            <div v-if="record.shippingToNames.length == 1">
              {{record.shippingToNames[0]}}
            </div>
            <dropdown
              v-if="record.shippingToNames.length > 1"
              :title="record.shippingToNames[0]"
              :options="record.shippingToNames.slice(1)"
              :labelExists="false"
            />
          </template>
          <template #action="{record}">
            <div class="table-actions">
              <sdButton
                class="btn-icon"
                type="default"
                @click="view(record)"
                shape="circle"
                :tooltip="isClient ? $t('views.shipmentRequest.details') : $t('actions.view')"
              >
                <sdFeatherIcons type="eye" size="16" />
              </sdButton>
              <sdButton
                v-if="$can('clone', record)"
                class="btn-icon"
                type="default"
                @click="clone(record)"
                shape="circle"
                :tooltip="isClient ? $t('views.shipmentRequest.clone') : $t('actions.view')"
              >
                <sdFeatherIcons type="copy" size="16" />
              </sdButton>
              <sdButton
                v-if="$can('edit', record)"
                class="btn-icon"
                type="default"
                @click="edit(record)"
                shape="circle"
                :tooltip="$t('actions.edit')"
              >
                <sdFeatherIcons type="edit" size="16" />
              </sdButton>
              <sdButton v-if="$can('publish', record) && companyStatus != 'approved'"
                :disabled="true"  class="btn-icon" type="default" shape="circle"
                :tooltip="$t('actions.publishDisabled')">
                <sdFeatherIcons type="send" size="16" />
              </sdButton>
              <pop-over
                v-if="hasShipments(record) && $can('publish', record) && companyStatus == 'approved'"
                @confirm="publish(record)"
                :title="$t('views.confirm.publishShipmentRequest.title')"
                :displayedMessage="$t('views.confirm.publishShipmentRequest.message')"
                :canInput="false"
              >
                <sdButton class="btn-icon" type="default" shape="circle" :tooltip="$t('actions.publish')">
                  <sdFeatherIcons type="send" size="16" />
                </sdButton>
              </pop-over>
              <pop-over
                v-if="$can('cancel', record)"
                @confirm="cancel(record)"
                :title="$t('views.confirm.cancelShipmentRequest.title')"
                :displayedMessage="$t('views.confirm.cancelShipmentRequest.message')"
                :canInput="false"
              >
                <sdButton
                  class="btn-icon"
                  type="default"
                  shape="circle"
                  :disabled="record.completedShipments"
                  :tooltip="record.completedShipments ? $t('messages.help.completedShipments') : $t('actions.cancel')"
                >
                  <sdFeatherIcons type="x-circle" size="16" />
                </sdButton>
              </pop-over>
            </div>
          </template>
        </a-table>
      </table-wrapper>
    </table-style-wrapper>
  </div>
</template>

<script>
import {
  computed, defineComponent, reactive, ref, watch
} from 'vue';
import {usePagination} from 'vue-request';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import moment from 'moment';
import {useI18n} from 'vue-i18n';
import {shipmentRequestTypes, shipmentRequestPublishStatus} from '@/constants';
import PopOver from '@/components/modals/PopOver.vue';
import VueTypes from 'vue-types';
import _ from 'lodash';
import Dropdown from '@/components/dropdown/Dropdown';

export default defineComponent({
  name: 'ShipmentRequestsTable',
  props: {
    searchValue: VueTypes.string.def(''),
    visibleColumnValue: VueTypes.object.def([])
  },
  components: {
    TableStyleWrapper,
    TableWrapper,
    PopOver,
    Dropdown
  },
  emits: ['showRequestDetails', 'searched'],
  setup(props, context) {
    const store = useStore();
    const router = useRouter();
    const {t} = useI18n();
    const companyStatus = computed(() => store.state.session.currentUser._jv?.relationships?.company?.status);

    const isClient = computed(() => store.state.session.userType === 'ClientUser');
    const existInColumnValue = (value) => Object.values(props.visibleColumnValue).includes(value);
    const sortDirections = ref(['ascend', 'descend', 'ascend']);

    const columns = computed(() => [
      {
        title: t('models.shipmentRequest.attributes.identifier'),
        dataIndex: 'identifier',
        visible: true,
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipmentRequest.attributes.requestDate'),
        dataIndex: 'createdAt',
        slots: {customRender: 'createdAt'},
        visible: true,
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipmentRequest.attributes.published'),
        dataIndex: 'published',
        slots: {customRender: 'published'},
        visible: isClient.value,
        sorter: true,
        sortDirections: sortDirections.value,
        filters: _.map(shipmentRequestPublishStatus, (value) => {
          return {
            text: t(`models.shipmentRequest.attributes.${value}`),
            value
          };
        })
      },
      {
        title: t('models.shipmentRequest.attributes.shipmentType'),
        dataIndex: 'shipmentType',
        slots: {customRender: 'shipmentType'},
        visible: existInColumnValue('shipmentType'),
        sorter: true,
        sortDirections: sortDirections.value,
        filters: _.map(shipmentRequestTypes, (value) => {
          return {
            text: t(`models.shipmentRequest.attributes.shipmentTypes.${value}`),
            value
          };
        })
      },
      {
        title: t('models.shipmentRequest.attributes.shippingFrom'),
        dataIndex: 'shippingFromName',
        visible: existInColumnValue('shippingFrom'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipmentRequest.attributes.shippingTo'),
        dataIndex: 'shippingToNames',
        slots: {customRender: 'shippingToNames'},
        visible: existInColumnValue('shippingTo')
      },
      {
        title: t('models.shipmentRequest.attributes.scope'),
        dataIndex: 'scope',
        visible: true,
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipmentRequest.attributes.reference'),
        dataIndex: 'reference',
        visible: existInColumnValue('reference'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipmentRequest.attributes.shippingMethod'),
        dataIndex: 'shippingMethod',
        visible: existInColumnValue('shippingMethod'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipmentRequest.attributes.clientName'),
        dataIndex: 'clientName',
        visible: !isClient.value && existInColumnValue('clientName'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: '',
        key: 'action',
        slots: {customRender: 'action'},
        visible: true
      }
    ]);

    const visibleColumns = computed(() => {
      return columns.value.filter((column) => column.visible);
    });

    const requestTypeClass = (record) => {
      return record.shipmentType === shipmentRequestTypes.EOR ? 'danger' : 'secondary';
    };

    const publishedClass = (record) => {
      return record.published ? 'success' : 'danger';
    };

    const getShipmentRequests = (params) => {
      return store.dispatch('getShipmentRequests', params);
    };

    const filtersState = reactive({
      search: undefined,
      shipmentType: undefined,
      published: undefined
    });

    const sortState = reactive({
      col: undefined, direction: undefined
    });

    const filtersChanged = ref(false);
    watch(
      () => props.searchValue,
      () => {
        filtersChanged.value = true;
        filtersState.search = props.searchValue;
        if (props.searchValue.length === 0 || props.searchValue.length >= 3) {
          setTimeout(() => {
            handleTableChange(pagination.value, filtersState, {});
            context.emit('searched');
          }, 100);
        }
      }
    );

    const {
      data: dataSource, run, mutate, loading, current, pageSize
    } = usePagination(getShipmentRequests, {
      formatResult: () => store.getters.shipmentRequests,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });

    const pagination = computed(() => ({
      total: store.getters.shipmentRequests?.totalEntries,
      current: current.value,
      pageSize: pageSize.value
    }));

    const handleTableChange = (pag, filters, sorter) => {
      const filterParams = {};
      _.forEach(filtersState, (field, key) => {
        filtersState[key] = filters[key] || field;
        filterParams[_.snakeCase(key)] = filtersState[key];
      });

      if (!_.isEmpty(sorter)) {
        sortState.col = _.snakeCase(sorter.field);
        sortState.direction = sorter.order === 'ascend' ? 'asc' : 'desc';
      }
      run({
        per_page: pag.pageSize,
        page: filtersChanged.value ? 1 : pag?.current,
        order_by: sortState.col,
        order_dir: sortState.direction,
        ...filterParams
      });
      filtersChanged.value = false;
    };

    const hasShipments = (record) => {
      return record.shipmentsNumber > 0 && record.shipmentsNumber - record.cancelledShipmentsNumber > 0;
    };

    const view = (record) => {
      const id = record._jv.id;
      isClient.value ?
        router.push({name: 'ClientShipmentRequestDetails', params: {shipmentRequestId: id}}) :
        router.push({name: 'AgentShipmentRequestDetails', params: {shipmentRequestId: id}});
    };

    const edit = (record) => {
      const id = record._jv.id;
      router.push({name: 'ClientEditShipmentRequest', params: {shipmentRequestId: id}});
    };

    const clone = (record) => {
      const id = record._jv.id;
      router.push({
        name: 'ClientCloneShipmentRequest',
        params: {shipmentRequestId: id}
      });
    };

    const publish = (record) => {
      loading.value = true;
      store.dispatch('publishShipmentRequest', record).then((status) => {
        loading.value = false;
        if (status === 200) mutate(store.getters.shipmentRequests);
      });
    };

    const cancel = (record) => {
      loading.value = true;
      store.dispatch('cancelShipmentRequest', record).then((status) => {
        loading.value = false;
        if (status === 200) mutate(store.getters.shipmentRequests);
      });
    };

    const customRow = (record) => {
      return {
        onClick: (e) => {
          if (isClient.value && e.target.tagName !== 'BUTTON') context.emit('showRequestDetails', record._jv.id);

          e.preventDefault();
        }
      };
    };

    return {
      isClient,
      dataSource,
      pagination,
      loading,
      visibleColumns,
      moment,
      requestTypeClass,
      hasShipments,
      view,
      edit,
      clone,
      publish,
      cancel,
      handleTableChange,
      companyStatus,
      publishedClass,
      customRow
    };
  }
});
</script>
